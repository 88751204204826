// App.js
import React, { useState, useEffect } from "react"
import AWS from "aws-sdk"

// Recharts components
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts"
import "./CostData.css"

// const s3 = new AWS.S3()

// Or if using AWS SDK v3:
// import { S3Client } from "@aws-sdk/client-s3"

// A helper function to transform x_axis and y_axis into Recharts-friendly data
const combineXY = (xValues, yValues) => {
  return xValues.map((x, idx) => ({
    x: x,
    y: yValues[idx],
  }))
}

function CostData() {
  const [dashboardData, setDashboardData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // Configure AWS (e.g., region). If your EC2 instance has an IAM role,
  // you usually just need the region. The credentials will be pulled from the instance metadata.
  AWS.config.update({
    region: "eu-west-1", // or your region
    accessKeyId: "AKIAUDDWUCC6UAGNI7WY",
    secretAccessKey: "FZGO2ZEH3f4FEfB8Z7tRAKWj6ERNxZ1j+CegRfAG",
  })

  const s3 = new AWS.S3()

  useEffect(() => {
    async function fetchS3Data() {
      try {
        // Build the S3 Key for "today's" data.
        const now = new Date()
        const year = now.getFullYear()
        const month = String(now.getMonth() + 1).padStart(2, "0") // '01', '02', ...
        const day = String(now.getDate()).padStart(2, "0") // '01', '02', ...

        const s3Key = `dashboard-data/${year}/${month}/${day}/daily_data.json`

        const params = {
          Bucket: "syft-cost-data",
          Key: s3Key,
        }

        // Download the object from S3
        const result = await s3.getObject(params).promise()

        // Convert buffer to string, then parse JSON
        const jsonString = result.Body.toString("utf-8")
        const data = JSON.parse(jsonString)

        // Set state so we can render
        setDashboardData(data)
        setLoading(false)
      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    }

    fetchS3Data()
  }, [])

  if (loading) return <p>Loading dashboard...</p>
  if (error) return <p>Error: {error}</p>
  if (!dashboardData) return null

  // --------------------------------
  // Element 1: Key metrics
  // --------------------------------
  const element1 = dashboardData.element1 || {}
  const elem1Keys = Object.keys(element1)

  // --------------------------------
  // Element 2: Bar chart
  // --------------------------------
  const barChartData = dashboardData.element2?.bar_chart
  let element2Data = []
  if (barChartData) {
    const { x_axis, y_axis } = barChartData
    element2Data = combineXY(x_axis, y_axis)
  }

  // --------------------------------
  // Element 3: numeric metric + line graph
  // --------------------------------
  const element3 = dashboardData.element3 || {}
  const lineGraph3 = element3.line_graph
  let element3Data = []
  if (lineGraph3) {
    const { x_axis, y_axis } = lineGraph3
    element3Data = combineXY(x_axis, y_axis)
  }

  // --------------------------------
  // Element 4: Two line graphs
  // --------------------------------
  const element4 = dashboardData.element4 || {}
  const lineGraph8Msgs = element4.line_graph_8msgs_day_cumulative
  const lineGraphMonthlyVsCalls = element4.line_graph_monthly_vs_calls

  let element4Graph1Data = []
  if (lineGraph8Msgs) {
    element4Graph1Data = combineXY(lineGraph8Msgs.x_axis, lineGraph8Msgs.y_axis)
  }

  let element4Graph2Data = []
  if (lineGraphMonthlyVsCalls) {
    element4Graph2Data = combineXY(
      lineGraphMonthlyVsCalls.x_axis,
      lineGraphMonthlyVsCalls.y_axis
    )
  }

  // --------------------------------
  // Element 5: Another bar chart
  // --------------------------------
  const element5 = dashboardData.element5?.barchart_pipeline_counts
  let element5Data = []
  if (element5) {
    element5Data = combineXY(element5.x_axis, element5.y_axis)
  }

  // --------------------------------
  // Render the Dashboard
  // --------------------------------
  return (
    <div style={{ padding: "1rem" }}>
      <h1>My LLM-based Nutrition App Dashboard</h1>

      {/* ---------- Element 1: Key Metrics ---------- */}
      <section style={{ marginBottom: "2rem" }}>
        <h2>Element 1: Key Metrics</h2>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {elem1Keys.map((key) => (
            <li key={key}>
              <strong>{key}:</strong> {element1[key]}
            </li>
          ))}
        </ul>
      </section>

      {/* ---------- Element 2: Bar Chart ---------- */}
      {barChartData && (
        <section style={{ marginBottom: "2rem" }}>
          <h2>Element 2: {barChartData.title}</h2>
          <BarChart
            width={800}
            height={400}
            data={element2Data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="x"
              label={{
                value: barChartData.x_axis_title,
                position: "insideBottom",
                dy: 10,
              }}
            />
            <YAxis
              label={{
                value: barChartData.y_axis_title,
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="y" fill="#82ca9d" name={barChartData.y_axis_title} />
          </BarChart>
        </section>
      )}

      {/* ---------- Element 3: Numeric + Line Chart ---------- */}
      <section style={{ marginBottom: "2rem" }}>
        <h2>Element 3: Average Cost per Message</h2>
        {element3["Average cost per message for yesterday"] && (
          <p>
            <strong>Yesterday:</strong>{" "}
            {element3["Average cost per message for yesterday"]}
          </p>
        )}

        {lineGraph3 && (
          <>
            <h3>{lineGraph3.title}</h3>
            <LineChart
              width={800}
              height={300}
              data={element3Data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="x"
                label={{
                  value: lineGraph3.x_axis_title,
                  position: "insideBottom",
                  dy: 10,
                }}
              />
              <YAxis
                label={{
                  value: lineGraph3.y_axis_title,
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="y"
                stroke="#8884d8"
                name={lineGraph3.y_axis_title}
                dot={true}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </>
        )}
      </section>

      {/* ---------- Element 4: Two Line Charts ---------- */}
      <section style={{ marginBottom: "2rem" }}>
        <h2>Element 4</h2>
        {/* 4.1 - 8 msgs/day cumulative */}
        {lineGraph8Msgs && (
          <div style={{ marginBottom: "2rem" }}>
            <h3>{lineGraph8Msgs.title}</h3>
            <LineChart
              width={800}
              height={300}
              data={element4Graph1Data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="x"
                label={{
                  value: lineGraph8Msgs.x_axis_title,
                  position: "insideBottom",
                  dy: 10,
                }}
              />
              <YAxis
                label={{
                  value: lineGraph8Msgs.y_axis_title,
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="y"
                stroke="#82ca9d"
                name={lineGraph8Msgs.y_axis_title}
                dot={true}
              />
            </LineChart>
          </div>
        )}

        {/* 4.2 - monthly cost vs #calls */}
        {lineGraphMonthlyVsCalls && (
          <div style={{ marginBottom: "2rem" }}>
            <h3>{lineGraphMonthlyVsCalls.title}</h3>
            <LineChart
              width={800}
              height={300}
              data={element4Graph2Data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="x"
                label={{
                  value: lineGraphMonthlyVsCalls.x_axis_title,
                  position: "insideBottom",
                  dy: 10,
                }}
              />
              <YAxis
                label={{
                  value: lineGraphMonthlyVsCalls.y_axis_title,
                  angle: -90,
                  position: "insideLeft",
                }}
              />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="y"
                stroke="#8884d8"
                name={lineGraphMonthlyVsCalls.y_axis_title}
                dot={true}
              />
            </LineChart>
          </div>
        )}
      </section>

      {/* ---------- Element 5: Pipeline Counts Bar Chart ---------- */}
      {element5 && (
        <section style={{ marginBottom: "2rem" }}>
          <h2>Element 5: {element5.title}</h2>
          <BarChart
            width={800}
            height={400}
            data={element5Data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="x"
              label={{
                value: element5.x_axis_title,
                position: "insideBottom",
                dy: 10,
              }}
            />
            <YAxis
              label={{
                value: element5.y_axis_title,
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="y" fill="#82ca9d" name={element5.y_axis_title} />
          </BarChart>
        </section>
      )}
    </div>
  )
}

export default CostData
